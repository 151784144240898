<template>
  <!-- 计价管理 -->
  <div class="carRentalValuationManage">
    <div ref="ActionBar" class="ActionBar">
      <div class="actionBar-box">
        <div class="col-1">所属车商：</div>
        <div class="col-2">
          <el-select
            v-model="queryForm.carServiceProviderIds"
            clearable
            multiple
            collapse-tags
            placeholder="请选择"
          >
            <el-option
              v-for="item in carDealerList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1 col-1-a">取车城市：</div>
        <div class="col-2 col-2-a">
          <el-select
            v-model="queryForm.cityIds"
            clearable
            multiple
            collapse-tags
            placeholder="请选择"
          >
            <el-option
              v-for="item in getCarCity"
              :key="item.id"
              :label="item.cityName"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1 col-1-a">取车区县：</div>
        <div class="col-2 col-2-a">
          <el-select
            v-model="queryForm.districtIds"
            clearable
            multiple
            collapse-tags
            placeholder="请选择"
          >
            <el-option
              v-for="item in getCarDistrict"
              :key="item.id"
              :label="item.districtName"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1 col-1-a">取车点：</div>
        <div class="col-2 col-2-a">
          <el-select
            v-model="queryForm.pickLocationIds"
            clearable
            multiple
            collapse-tags
            placeholder="请选择"
          >
            <el-option
              v-for="item in getCarPoint"
              :key="item.id"
              :label="item.pickLocationName"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1">车型分类：</div>
        <div class="col-2">
          <el-select
            v-model="queryForm.vehicleModelTypeIds"
            clearable
            multiple
            collapse-tags
            placeholder="请选择"
          >
            <el-option
              v-for="item in vehicleTypeDictList"
              :key="item.id"
              :label="item.vehicleTypeChildName"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1 col-1-a">车型名称：</div>
        <div class="col-2 col-2-a">
          <el-input v-model="queryForm.carModelName" clearable></el-input>
        </div>
      </div>
      <div class="actionBar-box">
        <el-button type="primary" size="small" @click="queryFun"
          >查询</el-button
        >
        <el-button
          v-if="listFind('导出')"
          type="primary"
          size="small"
          @click="exportFun"
          >导出</el-button
        >
      </div>
    </div>
    <Table
      :table-data="tableData"
      :title-name="titleName"
      :col-class-type="true"
      :operation="true"
    >
      <template slot-scope="scope">
        <el-button
          v-if="listFind('定价')"
          type="text"
          size="small"
          sort="primary"
          @click="updatePrice(scope.scopeRow)"
          >定价</el-button
        >
      </template>
    </Table>
    <div ref="pagination" class="pagination">
      <el-pagination
        background
        layout="total,sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="queryForm.pageSize"
        :current-page="queryForm.currentPage"
        @current-change="onCurrentChange"
        @size-change="onSizeChange"
      ></el-pagination>
    </div>
    <charter-popup
      v-if="show"
      :header-text="headerText"
      @confirmFun="confirmFun"
      @closePopupFun="clearForm"
    >
      <div
        style="width: 100%; padding: 30px 30px 0 30px; box-sizing: border-box"
      >
        <div class="showDetials">
          <img :src="dialogImageUrl" style="width: 320px" />
          <div style="width: 345px; background: #fafbff; margin-top: 10px">
            <p style="color: #999999; margin: 10px 0 0 10px">
              车辆配置: {{ carName }}
            </p>
            <div class="showParams">
              <div
                v-for="(i, index) in showParams"
                :key="index"
                class="paramsItem"
              >
                <div class="items">
                  <p>{{ i.value }}</p>
                  <p style="color: #999999">{{ i.label }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-form
        ref="FormData"
        :model="Form"
        :rules="rules"
        label-width="140px"
        label-position="right"
        class="demo-form dialog-form"
        style="margin-left: 25px"
      >
        <el-form-item label="车辆租赁及服务费" prop="rentalServicePrices">
          <el-input v-model="Form.rentalServicePrices" />
        </el-form-item>
        <el-form-item label="基础服务费" prop="baseServicePrices">
          <el-input v-model="Form.baseServicePrices" />
        </el-form-item>
        <el-form-item label="整备费" prop="procedurePrices">
          <el-input v-model="Form.procedurePrices" />
        </el-form-item>
        <el-form-item label="租车押金" prop="vehicleDeposit">
          <el-input v-model.number="Form.vehicleDeposit" type="number" />
          <span v-if="!Form.isPayDeposit" class="text">押金规则未启用</span>
        </el-form-item>
        <el-form-item label="违章押金" prop="violationDeposit">
          <el-input v-model.number="Form.violationDeposit" type="number" />
          <span v-if="!Form.isPayDeposit" class="text">押金规则未启用</span>
        </el-form-item>
      </el-form>
    </charter-popup>
    <!-- <el-dialog :visible.sync="dialogVisible" center top="20vh">
      <img :src="dialogImageUrl" style="width: 600px" />
    </el-dialog>-->
    <div
      v-show="dialogVisible"
      class="imgPopup"
      @click="() => (dialogVisible = false)"
    >
      <img :src="dialogImageUrl" alt @click.stop="() => {}" />
    </div>
  </div>
</template>

<script>
import {
  rentChargeManageTableAPI,
  renderCarTypeListAPI,
  rentalDistrictListAPI,
  rentalTableDataAPI,
  rentalCityListAPI,
  providerListAPI,
  updateRentPriceAPI,
  rentPriceManageExpAPI,
} from "@/api/lib/api.js";
import host from "@/api/static/host.js";
import charterPopup from "@/components/charterPopup/charterPopup.vue";
export default {
  components: { charterPopup },
  data() {
    return {
      carName: "",
      showParams: [
        {
          label: "变速箱",
          value: "",
        },
        {
          label: "油箱容量",
          value: "",
        },
        {
          label: "排量",
          value: "",
        },
        {
          label: "座位数",
          value: "",
        },
        {
          label: "进气",
          value: "",
        },
        {
          label: "倒车雷达",
          value: "",
        },
      ],
      params: ["", "", "", "", "", ""],
      headerText: "",
      Form: {
        id: "",
        rentalServicePrices: "",
        baseServicePrices: "",
        procedurePrices: "",
        vehicleDeposit: null,
        violationDeposit: null,
        isPayDeposit: null,
      },
      rules: {
        rentalServicePrices: [
          {
            required: true,
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (value === "") callback("请输入车辆租赁及服务费");
              else if (/^([1-9]|0)*(.\d{1,2})?$/.test(value)) callback();
              else callback("请输入整数/两位小数");
            },
          },
        ],
        baseServicePrices: [
          {
            required: true,
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (value === "") callback("请输入基础服务费");
              else if (/^([1-9]|0)*(.\d{1,2})?$/.test(value)) callback();
              else callback("请输入整数/两位小数");
            },
          },
        ],
        procedurePrices: [
          {
            required: true,
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (value === "") callback("请输入手续费");
              else if (/^([1-9]|0)*(.\d{1,2})?$/.test(value)) callback();
              else callback("请输入整数/两位小数");
            },
          },
        ],
        vehicleDeposit: [
          {
            required: true,
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (!value) callback("需填写大于0的整数");
              else if (/^[1-9][\d]*$/.test(value)) callback();
              else callback("需填写大于0的整数");
            },
          },
        ],
        violationDeposit: [
          {
            required: true,
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (!value) callback("需填写大于0的整数");
              else if (/^[1-9][\d]*$/.test(value)) callback();
              else callback("需填写大于0的整数");
            },
          },
        ],
      },
      show: false,
      dialogVisible: false,
      dialogImageUrl: "",
      tableData: [],
      titleName: [
        {
          title: "所属车商",
          props: "carServiceProviderName",
          width: 190,
        },
        {
          title: "车名",
          props: "carModelName",
          width: 120,
        },
        {
          title: "取车城市",
          props: "cityName",
        },
        {
          title: "取车区县",
          props: "districtName",
        },
        {
          title: "取车点",
          props: "pickLocationName",
        },
        {
          title: "车型分类",
          props: "vehicleTypeChildName",
        },
        {
          title: "车辆租赁及服务费",
          props: "rentalServicePrices",
          width: 150,
        },
        {
          title: "基础服务费",
          props: "baseServicePrices",
          width: 120,
        },
        {
          title: "整备费",
          props: "procedurePrices",
          width: 120,
        },
        {
          title: "租车押金",
          props: "vehicleDeposit",
        },
        {
          title: "违章押金",
          props: "violationDeposit",
          width: 120,
        },
        {
          title: "押金规则",
          props: "isPayDeposit",
          SpecialJudgment: (res) => (res ? "已开启" : "未开启"),
        },
        {
          title: "图片",
          props: "picture",
          render: (h, params, index) => {
            return h("i", {
              class: "iconfont its_gy_image",
              style: {
                fontSize: "18px",
                cursor: "pointer",
                color: "#336FFE",
              },
              on: {
                click: () => {
                  this.dialogVisible = true;
                  this.dialogImageUrl = host.imgURL + params.picture;
                },
              },
            });
          },
        },
      ],
      total: 0,
      queryForm: {
        carModelName: "",
        carServiceProviderIds: [],
        cityIds: [],
        districtIds: [],
        vehicleModelTypeIds: [],
        pickLocationIds: [],
        currentPage: 1,
        pageSize: 10,
      },
      carDealerList: [],
      getCarCity: [],
      getCarDistrict: [],
      getCarPoint: [],
      vehicleTypeDictList: [],
    };
  },
  methods: {
    renderData() {
      rentChargeManageTableAPI(this.queryForm).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    confirmFun() {
      this.$refs.FormData.validate((valid) => {
        if (valid) {
          updateRentPriceAPI(this.Form).then((res) => {
            this.show = false;
            this.renderData();
          });
        }
      });
    },
    clearForm() {
      this.show = false;
    },
    queryFun() {
      this.queryForm.currentPage = 1;
      this.queryForm.pageSize = 10;
      this.renderData();
    },
    onCurrentChange(val) {
      this.queryForm.currentPage = val;
      this.renderData();
    },
    onSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.currentPage = 1;
      this.renderData();
    },
    exportFun() {
      rentPriceManageExpAPI(this.queryForm).then((res) => {
        const blob = new Blob([res], { type: "application/vnd.ms-excel" });
        const fileName = "租车计价管理" + this.GMTToStr(new Date()) + ".xlsx"; //文件名
        const linkNode = document.createElement("a");

        linkNode.download = fileName; //a标签的download属性规定下载文件的名称
        linkNode.style.display = "none";
        linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
        document.body.appendChild(linkNode);
        linkNode.click(); //模拟在按钮上的一次鼠标单击

        URL.revokeObjectURL(linkNode.href); // 释放URL 对象
        document.body.removeChild(linkNode);
      });
    },
    updatePrice(scope) {
      this.headerText = "修改定价";
      this.show = true;
      this.Form.id = scope.id;
      this.Form.rentalServicePrices = scope.rentalServicePrices;
      this.Form.baseServicePrices = scope.baseServicePrices;
      this.Form.procedurePrices = scope.procedurePrices;
      this.Form.vehicleDeposit = scope.vehicleDeposit;
      this.Form.violationDeposit = scope.violationDeposit;
      this.Form.isPayDeposit = scope.isPayDeposit;
      this.dialogImageUrl = host.imgURL + scope.picture;
      this.params[0] = scope.gearbox;
      this.params[1] = scope.fuelTankCapacity;
      this.params[2] = scope.displacement;
      this.params[3] = scope.seatNum;
      this.params[4] = scope.intakeForm;
      this.params[5] = scope.reversingRadar;
      this.carName = scope.carModelName;
      if (this.params[5] == 0) {
        this.params[5] = "无";
      } else {
        this.params[5] = "有";
      }
      for (let i = 0; i < this.params.length; i++) {
        this.showParams[i].value = this.params[i];
      }
    },
  },
  mounted() {
    this.renderData();
    renderCarTypeListAPI({ businessType: 5 }).then((res) => {
      if (res.code == "SUCCESS") {
        this.vehicleTypeDictList = res.data;
      }
    });
    rentalCityListAPI().then((res) => {
      if (res.code == "SUCCESS") {
        this.getCarCity = res.data;
      }
    });
    rentalTableDataAPI().then((res) => {
      if (res.code == "SUCCESS") {
        this.getCarPoint = res.data;
      }
    });
    rentalDistrictListAPI({ status: 0 }).then((res) => {
      if (res.code == "SUCCESS") {
        this.getCarDistrict = res.data;
      }
    });
    providerListAPI({ openBusiness: 5 }).then((res) => {
      if (res.code == "SUCCESS") {
        this.carDealerList = res.data;
      }
    });
  },
};
</script>

<style scoped lang="scss">
.imgPopup {
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  img {
    max-width: 70%;
    max-height: 70%;
  }
}
.carRentalValuationManage {
  width: calc(100% - 40px);
  height: calc(100% - 32px);
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  background: #ffffff;
  padding: 16px 20px;
  .ActionBar {
    background: #f7f8f9;
    padding: 16px 0 0 16px;
    padding-bottom: 0;
    display: flex;
    margin-bottom: 16px;
    flex-flow: wrap;
    height: 114px;
    .actionBar-box:nth-child(4n) {
      margin-right: 0;
    }
    .actionBar-box {
      width: calc(25% - 18px);
      height: 32px;
      line-height: 32px;
      margin-right: 7px;
      margin-bottom: 16px;
      padding-right: 10px;
      .col-1-a {
        width: 90px !important;
      }
      .col-1 {
        width: 90px;
        display: inline-block;
      }
      .col-2-a {
        width: calc(100% - 110px) !important;
      }
      .col-2 {
        display: inline-block;
        width: calc(100% - 110px);
        .el-select {
          width: 100%;
        }
      }
    }
  }
  .showDetials {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .showParams {
      width: 345px;
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
      .paramsItem {
        width: 115px;
        height: 70px;
        .items {
          height: 40px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-top: 15px;
        }
      }
      p {
        text-align: center;
        box-sizing: border-box;
        font-size: 14px;
      }
      .paramsItem:nth-child(1) {
        border-bottom: 1px solid #f2f2f2;
        border-right: 1px solid #f2f2f2;
        box-sizing: border-box;
      }
      .paramsItem:nth-child(2) {
        border-bottom: 1px solid #f2f2f2;
        border-right: 1px solid #f2f2f2;
        box-sizing: border-box;
      }
      .paramsItem:nth-child(3) {
        border-bottom: 1px solid #f2f2f2;
        box-sizing: border-box;
      }
      .paramsItem:nth-child(4) {
        border-right: 1px solid #f2f2f2;
        box-sizing: border-box;
      }
      .paramsItem:nth-child(5) {
        border-right: 1px solid #f2f2f2;
        box-sizing: border-box;
      }
    }
  }
  .text {
    font-size: 13px;
    color: red;
  }
  /deep/.el-form-item__content {
    line-height: 30px;
  }
}
</style>
